import React from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Resume from "../assets/fullstack.pdf";
import {FaCode} from 'react-icons/fa'

const NavbarComponent = () => {
  const changeBackground = () => {
    let header = document.getElementsByClassName("header-nav")[0];
    if (window.scrollY < 200) {
      header.classList.remove("sticky");
    } else {
      header.classList.add("sticky");
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <Navbar
        className="header-nav"
        fixed="top"
        collapseOnSelect
        bg="dark"
        expand="lg"
      >
        <Fade left cascade>
          <Navbar.Brand href="#home" className="brand-name ml-5">
          <FaCode size={40} style={{marginTop : "-10px"}}/> 
          </Navbar.Brand>
        </Fade>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav"
          style={{ justifyContent : 'flex-end'}}>
          <Nav className="ml-auto mr-5">
            <Fade right cascade>
              <Nav.Link className="px-3" href="#home">
                Home
              </Nav.Link>
              <Nav.Link className="px-3" href="#about">
                Sobre
              </Nav.Link>
              <Nav.Link className="px-3" href="#work">
              Experiência
              </Nav.Link>
              <Nav.Link className="px-3" href="#project">
              Projetos
              </Nav.Link>
              <Nav.Link className="px-3" href="#contact">
              Contato
              </Nav.Link>
              <Nav.Link
                className="px-3"
                rel="noopener noreferrer"
                href={Resume}
                target="_blank"
              >
                <Button variant="outline-success" className="button">
                Currículo
                </Button>
              </Nav.Link>
            </Fade>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
