import React from 'react';
import { Fade } from "react-reveal";

const About = () => {
  return (
    <div
      style={{ background: "#0b0b0d", color: "white" }}
      className="main-div"
      id="about"
    >
      <Fade left cascade>
        <section className="div-1 about">
          <h1 className="heading">Sobre mim</h1>
          <div>
            <p className="text my-3">
              Sou aluno graduando em {" "}
              <span style={{ color: "#63c9c6" }}>
                analise em desenvolvimento de sistemas
              </span>{" "}
              na <span style={{ color: "#63c9c6" }}>Unicesumar</span>
              . Eu gosto de projetar e criar aplicativos e sites da web. Procuro e gosto de coisas que
              me desafiem e me façam pensar fora da caixa. Meu principal
              objetivo é ser capaz de construir produtos úteis, elegantes
              e eficientes.
            </p>
          </div>
          <p className="text mt-3">
            Aqui estão algumas tecnologias com as quais trabalho atualmente:          </p>
          <div className="skills-box">
            <ul className="lists">
              {["React/Redux", "Node", "Express", "Firebase"].map((text) => {
                return (
                  <li className="my-2 list-item" key={text}>
                    {text}
                  </li>
                );
              })}
            </ul>
            <ul className="lists">
              {["MongoDB", "SCSS", "Material UI", "PostgreSQL"].map((text) => {
                return (
                  <li className="my-2 list-item" key={text}>
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Fade>
    </div>
  );
}

export default About;
