import React from "react";
import CardComponent from "./card-component";
import Pro1 from "../assets/img/project-1.jpg";
import Pro2 from "../assets/img/project-2.jpg";
import Pro3 from "../assets/img/project-3.jpg";
import Pro4 from "../assets/img/project-4.jpg";

import { Fade } from "react-reveal";

const Projects = () => {
  return (
    <div
      style={{ background: "#0b0b0d", color: "white" }}
      className="main-div"
      id="project"
    >
      <Fade left cascade>
        <section className="div-1">
          <h1 className="heading">Projetos em destaque</h1>
          <div>
            {ProjectData.map((data, ind) => {
              const { github, external, img, title, text, skills } = data;
              return (
                <CardComponent
                  key={data + ind}
                  github={github}
                  external={external}
                  img={img}
                  title={title}
                  text={text}
                  skills={skills}
                />
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="https://github.com/marciosenaf?tab=repositories"
              target="_blank"
              rel="noopener noreferrer"
              type="button"
              className="btn btn-outline-light"
              style={{ width: "fit-content" }}
            >
              Mais Projetos
            </a>
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default Projects;

const ProjectData = [
  {
    title: "Sistema de empréstimo",
    img: Pro1,
    skills: "React.js, Redux, SASS, Node.js, Express.js, MongoDB",
    github: "https://github.com/marciosenaf/emprestimo",
    text: "Sistema de gerenciamento de empréstimos e pagamentos com sistema de login implementado",
  },
  {
    title: "Controle de vendas:",
    img: Pro2,
    skills:
      "React.js, Redux, SASS, Node.js, Express.js, MongoDB",
    github: "https://github.com/marciosenaf/controle-de-estoque",
    text: " Sistema de gerenciamento de entrada e saída de produtos com sistema de login",
  },
  {
    title: "Loja E-commerce",
    img: Pro3,
    skills: "React.js, Redux, Firebase, bootstrap, SASS",
    github: "https://github.com/marciosenaf/marcio-DEVendas",
    text: "Projeto de loja virtual completo, responsivo e com o sistema de login implementado",
    external: "https://marcio-devendas.pages.dev/home",
  },
  {
    title: "Sistema Financeiro",
    img: Pro4,
    skills: "React.js, Typescript, Styled-Components",
    github: "https://github.com/marciosenaf/sistema-financeiro",
    text: "Sistema de controle, balanço e registro de entrada e saída de dinheiro",
    external: "http://sistema-financeiro.pages.dev/",
  },
];