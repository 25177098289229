import React from "react";
import { Fade } from 'react-reveal';
import {AiOutlineMail} from "react-icons/ai"

const Contact = () => {
  return (
    <div className="contact-div" id="contact">
      <div className="head mx-5">
        <div className="main-div">
        <Fade top cascade>
          <section className="div-1 contact">
            <h4 className="my-3 " style={{textAlign:"center"}}>Entrar em contato</h4>
            <span>
            <span className="icons__email"><AiOutlineMail style={{ color: "#63c9c6", marginRight: "10px" }} size={25}/>
            </span><a style={{ color: " #BAE7E7", textDecoration: "none" }} target="_blank" href="mailto:contatomarciosena@gmail.com">contatomarciosena@gmail.com</a> 
            </span>
          </section>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Contact;
