import React from 'react';
import { Fade } from 'react-reveal';
import CardComponent from "./card-component";
import { FaFreeCodeCamp, FaAws, FaMicrosoft } from "react-icons/fa"
import { CgMicrosoft } from "react-icons/cg"

const Quote = () => {
    return (
        <div className="quote-div">
            <div className="head mx-5">
                <div className="main-div">
                    <Fade top cascade><>
                        <p className='title__certificates'>Últimos Certificados</p>
                        </>
                        <section className="div-1 quote cards_move"
                            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between", gap: "30px", }}>
                            <div class="card__certificates">
                                <p><FaAws size={50} /></p>
                                <span >AWS Cloud Practitioner Essentials</span> <h4><a
                                    href='https://drive.google.com/file/d/1tmxw_oV1JeuHY4jMfNk6sWmRBdxbbjEX/view'
                                    target="_blank"
                                    style={{ color: "#63c9c6", textDecoration: "none" }}>
                                    Veja aqui
                                </a></h4>

                            </div>
                            <div class="card__certificates">
                                <p><FaFreeCodeCamp size={50} /></p>
                                <span >Algorithms and Data Structures.</span> <h4><a
                                    href='https://www.freecodecamp.org/certification/marciosena/javascript-algorithms-and-data-structures'
                                    target="_blank"
                                    style={{ color: "#63c9c6", textDecoration: "none" }}>
                                    Veja aqui
                                </a></h4>

                            </div>
                            <div class="card__certificates" >
                                <p><CgMicrosoft size={50} /></p>
                                <span style={{ fontSize: "21px", }}>Desenvolvimento de Software por Microsoft.</span> <h4><a
                                    href='https://www.linkedin.com/learning/certificates/bc7de94f0dc1e8c57fd936b4c06a35e44819817e8a57fcad4785f469b6dc4888'
                                    target="_blank"
                                    style={{ color: "#63c9c6", textDecoration: "none" }}>
                                    Veja aqui
                                </a></h4>

                            </div>
                        </section>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Quote;
